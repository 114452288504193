import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@/sass/overrides.sass'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify)

const theme = {
  primary: '#d35400',
  secondary: '#e67e22',
  accent: '#8e44ad',
  info: '#2980b9',
}

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
