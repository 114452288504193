export default [
  {
    path: '/pages/contratos',
    name: 'Contratos',
    component: () => import('./components/List'),
  },
  {
    path: '/pages/contratos/create',
    name: 'Contratos > Novo',
    component: () => import('./components/Form'),
  },
  {
    path: '/pages/contratos/:id',
    name: 'Contratos > Editar',
    component: () => import('./components/Form'),
  },
]
