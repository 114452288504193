export function isCPFValid (cpf) {
  if (!cpf) return true
  cpf = cpf.replace(/[.-\s]/g, '')
  if (cpf.length < 11) return false

  let digitosIguais = true

  for (let i = 0; i < cpf.length - 1; i++) {
    if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
      digitosIguais = false
      break
    }
  }

  if (digitosIguais) return false

  const digitos = cpf.substring(9)
  let numeros = cpf.substring(0, 9)
  let soma = 0

  for (let i = 10; i > 1; i--) {
    soma += numeros.charAt(10 - i) * i
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== Number(digitos.charAt(0))) return false

  numeros = cpf.substring(0, 10)

  soma = 0
  for (let i = 11; i > 1; i--) {
    soma += numeros.charAt(11 - i) * i
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== Number(digitos.charAt(1))) return false

  return true
}

export function isEmailValid (email) {
  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function isTelValid (phone) {
  // https://pt.stackoverflow.com/questions/293950/valida%C3%A7%C3%A3o-campo-telefone
  const re = /^\([1-9]+\) [0-9]+-[0-9]+$/
  return re.test(phone)
}

export function getErrorData (error) {
  if (error.response) {
    const { response } = error
    if (response.data) {
      return response.data
    }
    return {}
  }
  return {}
}

export default {
  $isCPFValid: isCPFValid,
  $isEmailValid: isEmailValid,
  $isTelValid: isTelValid,
  $getErrorData: getErrorData,
}
