import Vue from 'vue'
import Vuex from 'vuex'
import { login } from './services/login.service'
import baseAPI from './http'
import router from './router'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    promotor: {
      namespaced: true,
      state: () => ({
        promotores: [],
      }),
      actions: {
        find ({ state }, id) {
          return new Promise((resolve, reject) => {
            const promotor = state.promotores.find(i => i.id === id)
            if (promotor) {
              resolve(promotor)
            } else {
              baseAPI.get(`usuarios/${id}`)
                .then(({ data }) => {
                  state.promotores.push(data)
                  resolve(data)
                })
                .catch(reject)
            }
          })
        },
      },
    },
  },
  state: {
    barColor: '#222f3e, #222f3e',
    barImage: '',
    drawer: null,
    token: localStorage.getItem('token') || false,
    user: {},
    requests: [],
    cupomSolicitacoes: [],
    creditRequests: [],
    totalLinhas: 0,
    totalPaginas: 0,
  },
  mutations: {
    AUTH_SUCCESS: (state, user) => {
      state.token = user.token
      state.user = user
    },
    LOGOUT: (state) => {
      state.status = ''
      state.token = false
      state.user = {}
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    HTTP_START (state, url) {
      state.requests.push(url)
    },
    HTTP_FINISH (state, url) {
      const index = state.requests.indexOf(url)
      if (index > -1) {
        state.requests.splice(index, 1)
      }
    },
  },
  actions: {
    login: ({ commit }, credentials) => {
      return login(credentials)
        .then(
          user => {
            localStorage.setItem('token', user.token)
            localStorage.setItem('user', JSON.stringify(user))
            commit('AUTH_SUCCESS', user)
            router.push('/')
            Vue.$toast.open({
              message: 'Login efetuado com sucesso!',
              type: 'success',
            })
          },
        )
        .catch(() => {
          Vue.$toast.open({
            message: 'Credenciais inválidas!',
            type: 'error',
          })
        })
    },
    logout: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('LOGOUT')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        delete baseAPI.defaults.headers.common.Authorization
        router.push('/pages/login')
        resolve()
      })
    },
    getCreditRequests ({ state }, params) {
      return baseAPI.get('creditrequest', { params })
        .then(({ data }) => {
          state.totalLinhas = data.total
          state.totalPaginas = data.totalPaginas
          state.creditRequests = data.rows
          return data
        })
    },
    updateCreditRequest (_, request) {
      if (request.creditValue) {
        request.creditValue = parseFloat(request.creditValue)
      }
      return baseAPI.put('solicitacao-credito', request)
    },
    getCupomSolicitacoes ({ state }, params) {
      return baseAPI.get('cupom/solicitacoes', { params })
        .then(({ data }) => {
          state.totalLinhas = data.total
          state.totalPaginas = data.totalPaginas
          state.cupomSolicitacoes = data.rows
          return data
        })
    },
    linkCupom (_, cupom) {
      return baseAPI.put('cupom', cupom)
    },
  },
  getters: {
    logged: state => !!state.token,
    user: state => state.user,
    requesting: state => state.requests.length > 0,
    creditRequests: state => state.creditRequests,
    cupomSolicitacoes: state => state.cupomSolicitacoes,
    totalLinhas: state => state.totalLinhas,
    totalPaginas: state => state.totalPaginas,
  },
})
