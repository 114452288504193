export const routes = [
  {
    path: '/pages/cupom/solicitacoes',
    name: 'Cupons solicitados',
    component: () => import('./components/CopumSolicitacoes'),
    meta: {
      module: 'cliente',
    },
  },
]
