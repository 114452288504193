
export default [
  {
    path: '/pages/centro-distribuicao',
    name: 'CentroDistribuicao',
    component: () => import('./components/CentroDistribuicaoList'),
    meta: {
      module: 'configurador-frete',
    },
  },
  {
    path: '/pages/centro-distribuicao/create',
    name: 'CentroDistribuicao > Novo',
    component: () => import('./components/CentroDistribuicaoForm'),
    meta: {
      module: 'configurador-frete',
    },
  },
  {
    path: '/pages/centro-distribuicao/:id',
    name: 'CentroDistribuicao > Editar',
    component: () => import('./components/CentroDistribuicaoForm'),
    meta: {
      module: 'configurador-frete',
    },
  },
]
