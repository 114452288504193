
export default [
  {
    path: '/pages/usuarios',
    name: 'Usuários',
    component: () => import('./components/UserList'),
    meta: {
      module: 'user',
    },
  },
  {
    path: '/pages/usuarios/create',
    name: 'Usuários > Novo',
    component: () => import('./components/UserForm'),
    meta: {
      module: 'user',
    },
  },
  {
    path: '/pages/usuarios/:id',
    name: 'Usuários > Editar',
    component: () => import('./components/UserForm'),
    meta: {
      module: 'user',
    },
  },
]
