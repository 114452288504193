import baseAPI from '@/http'

export function login (usuario) {
  return baseAPI.post('auth/login', usuario)
    .then(resp => {
      const user = resp.data
      baseAPI.defaults.headers.common.Authorization = `Bearer ${user.token}`
      return user
    })
}
