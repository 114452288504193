export default [
  {
    path: '/pages/acerto-virtual',
    name: 'Acerto Virtual',
    component: () => import('./components/AcertoList'),
    meta: {
      module: 'acertovirtual',
    },
  },
]
