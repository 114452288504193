
export default [
  {
    path: '/pages/pedidos/:tipo',
    name: 'Pedidos',
    component: () => import('./pages/PedidoList'),
    meta: {
      module: 'pedido',
    },
  },
  {
    path: '/pages/pedidos/detail/:id',
    name: 'Detalhes do Pedido',
    component: () => import('./pages/Pedido'),
    meta: {
      module: 'pedido',
    },
  },
]
