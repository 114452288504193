// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixin from './mixin'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/sweetalert2'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueToast from 'vue-toast-notification'
// Import one of available themes
import 'vue-toast-notification/dist/theme-default.css'

import baseAPI from './http'
import moment from 'moment'
import VueTheMask from 'vue-the-mask'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

Vue.config.productionTip = false
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  iconsGroup: 'mdi',
  // optional, default to 'md' (default vuetify icons before v2.0.0)
})
Vue.use(VueTheMask)
Vue.use(VueToast, {
  position: 'top-right',
})

const stringuser = localStorage.getItem('user')
if (stringuser) {
  store.commit('AUTH_SUCCESS', JSON.parse(stringuser))
}
const formater = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})

Vue.prototype.$toast = Vue.$toast
Vue.prototype.$http = baseAPI
Vue.prototype.$formater = formater

Vue.mixin(mixin)

Vue.filter('timestamp', (v) => {
  return !v ? '-' : moment(v).format('DD/MM/YYYY HH:mm')
})

Vue.filter('timestampfix', (v) => {
  return !v ? '-' : moment(v).add(3, 'hours').format('DD/MM/YYYY HH:mm')
})

Vue.filter('date', (v) => {
  return !v ? '-' : moment(v).format('DD/MM/YYYY')
})

Vue.filter('idade', (v) => {
  return !v ? '-' : `${moment().diff(v, 'years')} anos`
})

Vue.filter('currency', (v) => {
  return !v ? 'R$ 0.00' : formater.format(v)
})

Vue.filter('add30', (v) => {
  return !v ? '-' : moment(v).add(30, 'days').format('DD/MM/YYYY')
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
