export default [
  {
    path: '/promotor/dashboard',
    name: 'Dashboard do Promotor',
    component: () => import('./pages/Dashboard'),
    meta: {
      module: 'dashboard-do-promotor',
    },
  },
  {
    path: '/promotor/acertos',
    name: 'Acertos do Promotor',
    component: () => import('./pages/Acertos'),
    meta: {
      module: 'dashboard-do-promotor',
    },
  },
  {
    path: '/promotor/revendedores',
    name: 'Meus Revendedores',
    component: () => import('./pages/MeusRevendedores'),
    meta: {
      module: 'dashboard-do-promotor',
    },
  },
  {
    path: '/promotor/pagamentos',
    name: 'Meus Pagamentos',
    component: () => import('./pages/MeusPagamentos'),
    meta: {
      module: 'dashboard-do-promotor',
    },
  },
  {
    path: '/promotor/novos-revendedores',
    name: 'Novo Revendedor do Promotor',
    component: () => import('./pages/NovoRevendedor'),
    meta: {
      module: 'dashboard-do-promotor',
    },
  },
  {
    path: '/promotor/revendedor/:id',
    name: 'Detalhes do Revendedor do Promotor',
    component: () => import('./pages/DetalhesRevendedor'),
    meta: {
      module: 'dashboard-do-promotor',
    },
    props: true,
  },
  {
    path: '/promotores',
    name: 'Promotores',
    component: () => import('./pages/Promotores'),
    meta: {
      module: 'pagamento-do-promotor',
    },
  },
  {
    path: '/promotor/:promotorId/revendedores',
    name: 'Revendedores do Promotor',
    component: () => import('./pages/PromotorRevendedores'),
    meta: {
      module: 'pagamento-do-promotor',
    },
    props: true,
  },
  {
    path: '/promotor/:promotorId/pagamentos',
    name: 'Pagamentos do Promotor',
    component: () => import('./pages/Pagamentos'),
    meta: {
      module: 'pagamento-do-promotor',
    },
    props: true,
  },
]
