import axios from 'axios'
import store from '../store'

const baseAPI = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})

baseAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    store.commit('HTTP_START', config.url)
    return config
  },

  (error) => {
    store.commit('HTTP_FINISH', error.config.url)
    return Promise.reject(error)
  },
)

baseAPI.interceptors.response.use((response) => {
    store.commit('HTTP_FINISH', response.config.url)
    return response
}, (error) => {
    store.commit('HTTP_FINISH', error.config.url)
    if (error.response.status === 401) {
      const requestConfig = error.config
      store.dispatch('logout')
      return axios(requestConfig)
    }
  return Promise.reject(error)
})

export default baseAPI
