export default [
  {
    path: '/pages/inadimplentes/',
    name: 'Inadimplentes',
    component: () => import('./components/ClientesDevedoresList'),
    meta: {
      module: 'clientesdevedores',
    },
  },
  {
    path: '/pages/inadimplentes/:id/acordos/',
    name: 'Inadimplentes > Acordos',
    component: () => import('./components/CobrancasDevedorList'),
    meta: {
      module: 'clientesdevedores',
    },
  },
  {
    path: '/pages/inadimplentes/:id/acordos/novo/',
    name: 'Inadimplentes > Acordos > Novo',
    component: () => import('./components/NovoAcordo'),
    meta: {
      module: 'clientesdevedores',
    },
  },
]
