
export default [
  {
    path: '/pages/configurador-frete',
    name: 'Configurador de Frete',
    component: () => import('./components/ConfiguradorFreteList'),
    meta: {
      module: 'configurador-frete',
    },
  },
  {
    path: '/pages/configurador-frete/create',
    name: 'Configurador de Frete > Novo',
    component: () => import('./components/ConfiguradorFreteForm'),
    meta: {
      module: 'configurador-frete',
    },
  },
  {
    path: '/pages/configurador-frete/:id',
    name: 'Configurador de Frete > Editar',
    component: () => import('./components/ConfiguradorFreteForm'),
    meta: {
      module: 'configurador-frete',
    },
  },
]
