export default [
  {
    path: '/pages/gamefication/levels',
    name: 'Gamefication > Nível',
    component: () => import('./components/LevelList'),
    meta: {
      module: 'gamefication.level',
    },
  },
  {
    path: '/pages/gamefication/levels/create',
    name: 'Gamefication > Nível > Novo',
    component: () => import('./components/LevelForm'),
    meta: {
      module: 'gamefication.level',
    },
  },
  {
    path: '/pages/gamefication/levels/:id',
    name: 'Gamefication > Nível > Editar',
    component: () => import('./components/LevelForm'),
    meta: {
      module: 'gamefication.level',
    },
  },
]
