export default [
  {
    path: '/pages/ead/categorias',
    name: 'EAD > Categorias',
    component: () => import('./components/CategoriaList'),
    meta: {
      module: 'ead',
    },
  },
  {
    path: '/pages/ead/categorias/create',
    name: 'EAD > Categorias > Nova',
    component: () => import('./components/CategoriaForm'),
    meta: {
      module: 'ead',
    },
  },
  {
    path: '/pages/ead/categorias/:id',
    name: 'EAD > Categorias > Editar',
    component: () => import('./components/CategoriaForm'),
    meta: {
      module: 'ead',
    },
  },
  {
    path: '/pages/ead/videos',
    name: 'EAD > Videos',
    component: () => import('./components/VideoList'),
    meta: {
      module: 'ead',
    },
  },
  {
    path: '/pages/ead/videos/create',
    name: 'EAD > Videos > Novo',
    component: () => import('./components/VideoForm'),
    meta: {
      module: 'ead',
    },
  },
  {
    path: '/pages/ead/videos/:id',
    name: 'EAD > Videos > Editar',
    component: () => import('./components/VideoForm'),
    meta: {
      module: 'ead',
    },
  },
]
