
export default [
  {
    path: '/pages/produtos',
    name: 'Produtos',
    component: () => import('./components/ProdutoList'),
    meta: {
      module: 'produto',
    },
  },
  {
    path: '/pages/produtos/create',
    name: 'Criação de Produtos',
    component: () => import('./components/ProdutoForm'),
    meta: {
      module: 'produto',
    },
  },
  {
    path: '/pages/produtos/:id',
    name: 'Edição de Produtos',
    component: () => import('./components/ProdutoForm'),
    meta: {
      module: 'produto',
    },
  },
  {
    path: '/pages/configuracao/produto/:id',
    name: 'Produto > Ajuste de Configuração',
    component: () => import('./components/ConfiguracaoProdutoForm'),
    meta: {
      module: 'produto',
    },
  },
  {
    path: '/pages/produto/:produtoid/configuracao',
    name: 'Produto > Nova Configuração',
    component: () => import('./components/ConfiguracaoProdutoForm'),
    meta: {
      module: 'produto',
    },
  },
  {
    path: '/pages/kit',
    name: 'Produto > Novo kit',
    component: () => import('./components/KitForm'),
    meta: {
      module: 'produto',
    },
  },
]
