export default [
  {
    path: '/pages/tiposcontrato',
    name: 'TiposContrato',
    component: () => import('./components/List'),
  },
  {
    path: '/pages/tiposcontrato/create',
    name: 'TiposContrato > Novo',
    component: () => import('./components/Form'),
  },
  {
    path: '/pages/tiposcontrato/:id',
    name: 'TiposContrato > Editar',
    component: () => import('./components/Form'),
  },
]
