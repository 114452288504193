export default [
  {
    path: '/pages/grade/cores',
    name: 'Grade > Cor',
    component: () => import('./components/CorList'),
    meta: {
      module: 'cor',
    },
  },
  {
    path: '/pages/grade/cores/create',
    name: 'Grade > Cor > Nova',
    component: () => import('./components/CorForm'),
    meta: {
      module: 'cor',
    },
  },
  {
    path: '/pages/grade/cores/:id',
    name: 'Grade > Cor > Editar',
    component: () => import('./components/CorForm'),
    meta: {
      module: 'cor',
    },
  },
]
