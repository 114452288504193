export const routes = [
  {
    path: '/pages/credito/solicitacoes',
    name: 'Solicitações de Crédito',
    component: () => import('./components/Main'),
    meta: {
      module: 'cliente',
    },
  },
]
