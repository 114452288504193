import Vue from 'vue'
import Router from 'vue-router'
import { routes as clientes } from './views/clientes'
import { routes as pedidos } from './views/pedidos'
import { routes as produtos } from './views/produtos'
import { routes as ead } from './views/ead'
import { routes as cores } from './views/grade/cor'
import { routes as tamanhos } from './views/grade/tamanho'
import { routes as fretes } from './views/fretes'
import { routes as perfis } from './views/perfis'
import { routes as acertovirtual } from './views/acertovirtual'
import { routes as inadimplentes } from './views/inadimplentes'
import { routes as usuarios } from './views/usuarios'
import { routes as solicitacoes } from './views/solicitacoes-credito'
import { routes as cupons } from './views/cupons'
import { routes as centros } from './views/centro-distribuicao'
import { routes as banners } from './views/banners'
import { routes as linksuteis } from './views/linksuteis'
import { routes as promotor } from './views/promotor'
import { routes as levels } from './views/gamefication/levels'
import { routes as contratos } from './views/contratos'
import { routes as tiposContrato } from './views/tiposcontrato'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'Minha Conta',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Login',
          path: 'pages/login',
          component: () => import('@/views/dashboard/pages/Login'),
        },
        {
          name: 'Logout',
          path: 'logout',
          component: () => import('@/views/dashboard/pages/Logout'),
        },
        ...clientes,
        ...pedidos,
        ...produtos,
        ...ead,
        ...fretes,
        ...perfis,
        ...acertovirtual,
        ...inadimplentes,
        ...usuarios,
        ...solicitacoes,
        ...cupons,
        ...centros,
        ...banners,
        ...linksuteis,
        ...promotor,
        ...cores,
        ...tamanhos,
        ...levels,
        ...contratos,
        ...tiposContrato,
        { path: '*', redirect: '/' },
      ],
    },
  ],
})
