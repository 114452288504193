export default [
  {
    path: '/pages/linksuteis',
    name: 'LinksUteis',
    component: () => import('./components/List'),
  },
  {
    path: '/pages/linksuteis/create',
    name: 'LinksUteis > Novo',
    component: () => import('./components/Form'),
  },
  {
    path: '/pages/linksuteis/:id',
    name: 'LinksUteis > Editar',
    component: () => import('./components/Form'),
  },
]
