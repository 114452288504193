
export default [
  {
    path: '/pages/perfil',
    name: 'Perfil',
    component: () => import('./components/PerfilList'),
    meta: {
      module: 'perfil',
    },
  },
  {
    path: '/pages/perfil/create',
    name: 'Perfil > Novo',
    component: () => import('./components/PerfilForm'),
    meta: {
      module: 'perfil',
    },
  },
  {
    path: '/pages/perfil/:id',
    name: 'Perfil > Editar',
    component: () => import('./components/PerfilForm'),
    meta: {
      module: 'perfil',
    },
  },
]
