export default [
  {
    path: '/pages/grade/tamanhos',
    name: 'Grade > Tamanho',
    component: () => import('./components/TamanhoList'),
    meta: {
      module: 'tamanhos',
    },
  },
  {
    path: '/pages/grade/tamanhos/create',
    name: 'Grade > Tamanho > Nova',
    component: () => import('./components/TamanhoForm'),
    meta: {
      module: 'tamanhos',
    },
  },
  {
    path: '/pages/grade/tamanhos/:id',
    name: 'Grade > Tamanho > Editar',
    component: () => import('./components/TamanhoForm'),
    meta: {
      module: 'tamanhos',
    },
  },
]
