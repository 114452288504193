
export default [
  {
    path: '/pages/clientes',
    name: 'Revendedoras',
    component: () => import('./pages/ClienteList'),
    meta: {
      module: 'cliente',
    },
  },
  {
    path: '/pages/clientes/create',
    name: 'Revendedora - cadastro manual',
    component: () => import('./pages/ClienteForm'),
    meta: {
      module: 'cliente',
    },
  },
  {
    path: '/pages/clientes/:id',
    name: 'Visualizando Revendedora',
    component: () => import('./pages/ClienteForm'),
    meta: {
      module: 'cliente',
    },
  },
]
