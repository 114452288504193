export default [
  {
    path: '/pages/banners',
    name: 'Banner',
    component: () => import('./components/List'),
  },
  {
    path: '/pages/banners/create',
    name: 'Banner > Novo',
    component: () => import('./components/Form'),
  },
  {
    path: '/pages/banners/:id',
    name: 'Banner > Editar',
    component: () => import('./components/Form'),
  },
]
